import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Jumbotron from "../components/Jumbotron"
import Layout from "../components/Layout"
import Posts from "../components/Posts"

const TagTemplate = ({ data, pageContext }) => {
  const { name, description } = data.tagsJson
  const { posts } = pageContext

  return (
    <Layout>
      <Helmet>
        <title>{name} • Elliot Wright</title>
        <meta name="description" content={description} />
      </Helmet>

      <Jumbotron title={`Posts in '${name}'`} description={description} />
      <Posts posts={posts} />
    </Layout>
  )
}

export const query = graphql`
  query($tag: String!) {
    tagsJson(slug: {eq: $tag}) {
      slug
      name
      description
    }
  }
`

export default TagTemplate
